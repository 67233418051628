import * as Sentry from "@sentry/react"
import confusedClouds from "assets/illustrations/confusedClouds.svg"
import forestLine from "assets/illustrations/forestLine.svg"
import { useEffect, type ReactNode } from "react"
import s from "./styles.module.scss"

type ErrorFallbackProps = {
  title: ReactNode
  message?: ReactNode
  error: unknown | unknown[]
}
export const ErrorFallback = ({
  title,
  message,
  error,
}: ErrorFallbackProps) => {
  // TODO [sentry-logging] https://jira.attensi.com/browse/WEB-18745
  // Find a better place to send Sentry logs from
  useErrorSentryLog(error)

  return (
    <div className={s.error} aria-live="polite">
      <div className={s.illustration}>
        <img src={confusedClouds} className={s.illustration__top} alt="" />
        <img src={forestLine} className={s.illustration__bottom} alt="" />
      </div>

      <div className={s.content}>
        <div className={s.content__title}>{title}</div>
        {message && <div className={s.content__message}>{message}</div>}
      </div>
    </div>
  )
}

// TODO [sentry-logging] https://jira.attensi.com/browse/WEB-18745
// Find a better place to send Sentry logs from
export const useErrorSentryLog = (error: unknown | unknown[]) => {
  useEffect(() => {
    const errorArray = Array.isArray(error) ? error : [error]
    for (const error of errorArray) {
      Sentry.captureException(error)
    }
  }, [error])
}
