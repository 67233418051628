import recentActivityIcon from "assets/icons/recentActivityIcon.svg"
import { Card, useErrorSentryLog } from "materia"
import { useTranslation } from "react-i18next"
import s from "./styles.module.scss"

export const RecentActivityWidgetError = ({ error }: { error: unknown }) => {
  // TODO [sentry-logging] https://jira.attensi.com/browse/WEB-18745
  // Find a better place to send Sentry logs from
  useErrorSentryLog(error)
  const { t } = useTranslation()

  return (
    <Card borderRadius="24">
      <div className={s.widget}>
        <div className={s.widget__top}>
          <img className={s.widget__icon} src={recentActivityIcon} alt="" />
          <h2 className={s.widget__title}>{t("activityFeed.activity")}</h2>
        </div>
        <p className={s.widget__error}>
          {t("error.RECENT_ACTIVITY_FETCH_ERROR")}
        </p>
      </div>
    </Card>
  )
}
