import { mockData, registerMockData, type ResultOf } from "graphql-mock"
import { graphql } from "../../../graphql"
import { mockNavigationData, navigationFragment } from "../Navigation/gql"

export type Data = ResultOf<typeof query>

export const query = graphql(
  `
    query Header {
      navigationCompany: currentCompany {
        ...Navigation
      }
    }
  `,
  [navigationFragment]
)

export const mockHeaderData = mockData<Data>({
  navigationCompany: mockNavigationData(),
})

registerMockData(query, mockHeaderData())
