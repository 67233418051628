import {
  useContext,
  type ReactNode,
  createContext,
  useState,
  useEffect,
} from "react"
import { AIChat } from "../index"

export const AIChatContext = createContext({
  openChat: () => {},
})

export const useAIChatContext = () => {
  const context = useContext(AIChatContext)
  if (!context) {
    throw Error(
      "AIChatContext not present. You are probably outside the AI chat context provider. Check that the component using the context is within the boundaries of the context."
    )
  }

  return context
}

export const AIChatContextProvider = ({
  children,
}: {
  children: ReactNode
}) => {
  // Read initial value from local storage,
  // This allows us to remember if the chat was open between hard refreshes and logins.
  const [isOpen, setIsOpen] = useState(
    localStorage.getItem("isAIChatOpen") === "true"
  )

  useEffect(() => {
    // Update local storage when chat is opened/closed
    localStorage.setItem("isAIChatOpen", JSON.stringify(isOpen))
  }, [isOpen])

  const openChat = () => {
    if (isOpen) return
    setIsOpen(true)
  }

  return (
    <AIChatContext.Provider value={{ openChat }}>
      <>
        {children}

        {<AIChat isOpen={isOpen} onClose={() => setIsOpen(false)} />}
      </>
    </AIChatContext.Provider>
  )
}
