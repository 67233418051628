import arrow from "assets/icons/arrowRight.svg"
import { formatDate } from "format"
import { useGraphqlQuery } from "graphql-mock"
import { Card } from "materia"
import { useTranslation } from "react-i18next"
import { modifiers } from "ui-tools"
import { FiestaImage, FiestaLink } from "utility-components"
import { getProductLink } from "../../../dataUtilities/productDataUtilities"
import { EventWidgetMobileError } from "./error"
import { query, type Course } from "./gql"
import { EventWidgetMobileLoading } from "./loading"
import s from "./styles.module.scss"

export const EventWidgetMobile = () => {
  const { data, error, isPending } = useGraphqlQuery(query, {})

  if (isPending) return <EventWidgetMobileLoading />
  if (error) return <EventWidgetMobileError error={error} />

  const product = data.products.nodes[0]
  const course = product?.course
  const isEmpty = !product || !course

  const isDisabled = data.currentCompany.portalFeatureFlags.includes(
    "disable_event_widget_page"
  )

  if (isDisabled || isEmpty) return
  if (course === null) return

  const { isAttending } = course

  return (
    <Card borderRadius="24">
      <div className={modifiers(s, "widget", { isAttending })}>
        <FiestaLink
          className={modifiers(s, "widget__item", { isAttending, isEmpty })}
          to={getProductLink(product.id, "meet")}
          replace={true}
        >
          <DateInfo course={course} />
          <div className={s.widget__wrapper}>
            <Info course={course} />
            <div className={s.widget__title}>{product.title}</div>
          </div>
          <FiestaLink
            to="/events"
            className={s.widget__arrowContainer}
            ariaLabel="Open event page"
          >
            <FiestaImage
              className={s.widget__arrow}
              src={arrow}
              alt="Arrow"
              sizes="60px"
            />
          </FiestaLink>
        </FiestaLink>
      </div>
    </Card>
  )
}

const DateInfo = ({ course }: { course: Course }) => {
  const { i18n } = useTranslation()

  return (
    <div className={s.date}>
      <div className={s.date__month}>
        {formatCourseMonth(course, i18n.language)}
      </div>
      <div className={s.date__day}>{formatCourseDay(course)}</div>
    </div>
  )
}

const Info = ({ course }: { course: Course }) => {
  const { t } = useTranslation()
  const { isAttending } = course

  return (
    <div className={modifiers(s, "info", { isAttending })}>
      {isAttending ? t("event.ATTENDING") : t("event.ATTEND")}
    </div>
  )
}

const formatCourseDay = (course: Course) => {
  const startDatetime = course.startDatetime
  const date = new Date(startDatetime)

  return date.getDate().toString()
}

const formatCourseMonth = (course: Course, locale: string) => {
  const startDatetime = course.startDatetime

  return formatDate(startDatetime, locale, {
    month: "short",
  })
}
