import { type ResultOf } from "graphql-mock"
import { graphql } from "../../../graphql"

export type Data = ResultOf<typeof query>

export const query = graphql(`
  query CurrentUserProvider {
    currentUser {
      id
      anonymous
      avatar
      avatarTitle
      department
      displayName
      email
      firstName
      lastName
      name
      phoneNumber
      portalLocale
      position
      roles
      username
    }
  }
`)
