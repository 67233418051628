import type { GraphQLEnvironment } from "graphql-mock"
import { BACKOFFICE_API_URL } from "./env"
import { getAttensiProductPlatformForOS } from "./tools/getAttensiProductPlatformForOS"

export const TEXT_FALLBACK = "N/A"
export const ATTENSI_PRODUCT_PLATFORM_FOR_OS = getAttensiProductPlatformForOS()
export const GRAPHQL_ENVIRONMENT: GraphQLEnvironment = {
  baseUrl: BACKOFFICE_API_URL,
  path: "/api/bff/portal/graphql",
}
