import { Dialog, ErrorFallback, Modal } from "materia"
import { useTranslation } from "react-i18next"
import { modifiers } from "ui-tools"
import { CloseButton } from "."
import s from "./styles.module.scss"

export const FullScreenViewerError = ({
  error,
  onClose,
}: {
  error: unknown
  onClose: () => void
}) => {
  const { t } = useTranslation()
  return (
    <div className={s.fullScreenViewerError}>
      <div className={s.fullScreenViewerError__header}>
        <CloseButton onClick={onClose} />
      </div>
      <ErrorFallback
        error={error}
        title={t("error.GENERIC")}
        message={t("error.FULL_SCREEN_ERROR")}
      />
    </div>
  )
}

export const FramedViewerError = ({
  error,
  orientation,
  onClose,
}: {
  error: unknown
  orientation: "portrait" | "landscape"
  onClose: () => void
}) => {
  const { t } = useTranslation()
  return (
    <Dialog ariaLabel="Product load error" onClose={onClose}>
      <div className={modifiers(s, "framedViewerError", orientation)}>
        <div className={s.framedViewerError__closeButton}>
          <CloseButton onClick={onClose} />
        </div>
        <div className={s.framedViewerError__content}>
          <ErrorFallback
            error={error}
            title={t("error.GENERIC")}
            message={t("error.FRAMED_VIEW_ERROR")}
          />
        </div>
      </div>
    </Dialog>
  )
}

export const ViewerErrorDialog = ({
  error,
  onClose,
}: {
  error: unknown
  onClose: () => void
}) => {
  const { t } = useTranslation()
  return (
    <Modal ariaLabel="Viewer load error" overflowAuto={false} onClose={onClose}>
      <div className={s.viewerError}>
        <div className={s.viewerError__closeButton}>
          <CloseButton onClick={onClose} />
        </div>
        <div className={s.viewerError__content}>
          <ErrorFallback
            error={error}
            title={t("error.GENERIC")}
            message={t("error.VIEWER_ERROR")}
          />
        </div>
      </div>
    </Modal>
  )
}
