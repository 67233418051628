import { formatNumber } from "format"
import { useQueryParam } from "hooks"
import { leaderboard } from "leaderboard-api"
import { useTranslation } from "react-i18next"
import { custom } from "../../../bonzai/bonzai"
import { useFormatUser } from "../../../bonzai/useFormatUser"
import { HiddenHeader } from "../../HiddenHeader"
import { ProductModalActivity } from "../ProductModalActivity/ProductModalActivity"
import { ProductModalLeaderboard } from "./ProductModalLeaderboard"
import { ProductModalLeaderboardError } from "./error"

export const ProductModalLeaderboardLoader = ({
  productId,
}: {
  productId: number
}) => {
  const { t, i18n } = useTranslation()
  const formatUser = useFormatUser()

  const [queryScope] = useQueryParam("scope")

  const { data: highScores, error } = useData(productId, queryScope)

  if (error) return <ProductModalLeaderboardError error={error} />

  if (highScores === undefined) return <ProductModalActivity.Skeleton />

  const { entries, my_entry } = highScores

  const podium = entries
    .slice(0, 3)
    .map((entry, index) => (
      <ProductModalLeaderboard.PodiumItem
        key={index}
        image={entry.user.image}
        name={formatUser(entry.user)}
        rank={entry.rank}
        score={formatNumber(entry.value, i18n.language)}
      />
    ))

  const entryElements = entries
    .slice(3)
    .map((entry, index) => (
      <ProductModalLeaderboard.Entry
        key={index}
        image={entry.user.image}
        name={formatUser(entry.user)}
        rank={entry.rank}
        score={formatNumber(entry.value, i18n.language)}
      />
    ))

  return (
    <ProductModalLeaderboard>
      <HiddenHeader title={t("leaderboard.LEADERBOARD")} tag="h3" />
      <ProductModalLeaderboard.Podium>{podium}</ProductModalLeaderboard.Podium>
      <ProductModalLeaderboard.Entries>
        {entryElements}
      </ProductModalLeaderboard.Entries>
      <ProductModalLeaderboard.MyEntry>
        <ProductModalLeaderboard.Entry
          image={my_entry.user.image}
          name={formatUser(my_entry.user)}
          rank={my_entry.rank}
          score={formatNumber(my_entry.value, i18n.language)}
        />
      </ProductModalLeaderboard.MyEntry>
    </ProductModalLeaderboard>
  )
}

const useData = (product_id: number, queryScope: string | undefined) => {
  const { data: firstScope, error: errorLeaderboardScopes } =
    custom.getLeaderboardScopes.useQuery([], {
      // TODO [error-handling-effort] https://jira.attensi.com/browse/WEB-18497
      // When the task above is done, we'll remove throwOnError
      throwOnError: false,
      select: (res) => res[0]?.value,
      enabled: queryScope === undefined,
    })

  const scope = queryScope ?? firstScope

  const { data, error: errorHighScores } = leaderboard.getHighScores.useQuery(
    [{ product_id, scope: scope!, length: 50 }],
    {
      // TODO [error-handling-effort] https://jira.attensi.com/browse/WEB-18497
      // When the task above is done, we'll remove throwOnError
      throwOnError: false,
      enabled: scope !== undefined,
    }
  )

  const error = errorLeaderboardScopes ?? errorHighScores

  return { data, error }
}
