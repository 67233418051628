import { modifiers } from "ui-tools"
import { icons, type IconType } from "./icons"

import s from "./Icon.module.scss"

/**
 * A basic Icon component.
 *
 * Icons ready to use with the context of this component reside in `assets/icons_new` directory.
 *
 * Icons remaining in the `assets/icons` directory will be gradually adapted to use in this component.
 */
export const Icon = ({
  icon,
  size = "medium",
  rotate = 0,
}: {
  /**
   * Name of the icon to display.
   */
  icon: IconType
  /**
   * Clockwise rotation of the icon (in degrees).
   */
  rotate?: 0 | 90 | 180 | 270
  /**
   * Size of the icon.
   * `"small" | "medium" | "large" | "extraLarge"`
   */
  size?: "small" | "medium" | "large" | "extraLarge"
}) => {
  const IconSVG = icons[icon]

  return <IconSVG className={modifiers(s, "icon", size, `rotate-${rotate}`)} />
}
