import type { TypedDocumentNode } from "@graphql-typed-document-node/core"
import {
  useInfiniteQuery,
  type InfiniteData,
  type QueryFunction,
  type QueryKey,
  type UseInfiniteQueryResult,
} from "@tanstack/react-query"
import { print } from "graphql"
import { useContext } from "react"
import { never } from "utils"
import { EnvironmentContext } from "./EnvironmentContext"
import { ForceStateContext, MockInputMapContext, mockMap } from "./context"
import { graphqlRequest } from "./graphqlRequest"

type Options<TData> = {
  getNextPage: (page: TData) => number | null
  initialData?: TData
  enabled?: boolean
}

type PageParam = { page: number }

export const useInfiniteGraphqlQuery = <
  TData,
  TVariables extends Record<string, any>
>(
  query: TypedDocumentNode<TData, TVariables>,
  variables: TVariables,
  options: Options<TData>
): UseInfiniteQueryResult<InfiniteData<TData>, Error> => {
  const forceState = useContext(ForceStateContext)
  const mockInputMap = useContext(MockInputMapContext)
  const enabled = forceState === "live"

  const environment = useContext(EnvironmentContext)
  if (!environment) throw new Error("Missing environment context")

  const queryKey = ["infinite", print(query), variables]

  const queryFn: QueryFunction<TData, QueryKey, PageParam> = ({
    pageParam,
    signal,
  }) => {
    return graphqlRequest({
      query,
      environment,
      signal,
      variables: { ...variables, ...pageParam },
    })
  }

  const getNextPageParam = (page: TData) => {
    const nextPage = options.getNextPage(page)
    if (nextPage === null) return
    return { page: nextPage }
  }

  const initialData = options.initialData
    ? { pages: [options.initialData], pageParams: [{ page: 1 }] }
    : undefined

  const queryResult = useInfiniteQuery<
    TData,
    Error,
    InfiniteData<TData>,
    QueryKey,
    PageParam
  >({
    enabled,
    queryKey,
    queryFn,
    initialPageParam: { page: 1 },
    getNextPageParam,
    initialData,
  })

  if (forceState === "live") {
    return queryResult
  }

  if (forceState === "loading") {
    return {
      ...queryResult,
      data: undefined,
      error: null,
      isError: false,
      isPending: true,
      isLoading: true,
      isLoadingError: false,
      isRefetchError: false,
      isSuccess: false,
      status: "pending",
      refetch: never,
      fetchNextPage: never,
      fetchPreviousPage: never,
    }
  }

  if (forceState === "mock") {
    const mockInputData = mockInputMap?.get(query)
    const mockData = mockMap.get(query)
    const data = mockInputData ?? mockData

    return {
      ...queryResult,
      data: { pages: [data], pageParams: [{}] },
      error: null,
      isError: false,
      isLoading: false,
      isLoadingError: false,
      isPending: false,
      isRefetchError: false,
      isSuccess: true,
      status: "success",
      refetch: never,
      fetchNextPage: never,
      fetchPreviousPage: never,
    }
  }

  if (forceState === "error") {
    return {
      ...queryResult,
      data: {} as InfiniteData<TData>,
      error: new Error("This is a forced error. This is only a test."),
      isPending: false,
      isError: true,
      isLoading: false,
      isLoadingError: false,
      isRefetchError: true,
      isSuccess: false,
      status: "error",
      refetch: never,
    }
  }

  return queryResult
}
