import { ErrorFallback } from "materia"
import { useTranslation } from "react-i18next"
import { ProductModal } from ".."

export const ProductModalLeaderboardError = ({ error }: { error: unknown }) => {
  const { t } = useTranslation()
  return (
    <ProductModal.Side>
      <ErrorFallback
        error={error}
        title={t("error.GENERIC")}
        message={t("error.LEADERBOARDS_FETCH_ERROR")}
      />
    </ProductModal.Side>
  )
}
