import { ErrorFallback } from "materia"
import { useTranslation } from "react-i18next"

import s from "./DiplomaPreview.module.scss"

export const DiplomaPreviewError = ({ error }: { error: unknown }) => {
  const { t } = useTranslation()
  return (
    <div className={s.error}>
      <ErrorFallback
        error={error}
        title={t("error.GENERIC")}
        message={t("error.DIPLOMA_FETCH_ERROR")}
      />
    </div>
  )
}
