import { useLayoutEffect, type ReactNode } from "react"
import { createPortal } from "react-dom"
import FocusLock from "react-focus-lock"
import { useDialogContainerContext } from "./context"
import s from "./styles.module.scss"

export * from "./container"

/*
 !NOTE!
 If you're here because you need a modal, please use the <Modal/> component - ei. not the dialog.
 The modal is intended to support all modal needs, and enforce sizes, accessibility etc.
 The modal uses the dialog under the hood.
 */
export const Dialog = ({
  children,
  onClose,
  ariaLabel,
  className,
}: {
  children: ReactNode
  ariaLabel: string
  onClose: () => void
  className?: string
}) => {
  const context = useDialogContainerContext()

  useInert(context.backgroundElement)

  if (!context.backgroundElement || !context.modalElement) return

  const element = (
    <FocusLock autoFocus={false}>
      <div
        className={`${s.dialog} ${className ?? ""}`}
        role="dialog"
        aria-modal="true"
        aria-label={ariaLabel}
      >
        <div className={s.dimmer} aria-hidden="true" onClick={onClose} />
        {children}
      </div>
    </FocusLock>
  )

  return createPortal(element, context.modalElement)
}

const useInert = (element: HTMLDivElement | null) => {
  useLayoutEffect(() => {
    if (!element) return

    element.setAttribute("inert", "true")
    return () => element.removeAttribute("inert")
  }, [element])
}
