import { Card, ErrorFallback } from "materia"
import { useTranslation } from "react-i18next"
import s from "./styles.module.scss"

export const FeaturedProductsError = ({ error }: { error: unknown }) => {
  const { t } = useTranslation()
  return (
    <Card borderRadius="24">
      <div className={s.featured} data-chromatic="ignore">
        <div className={s.featured__products}>
          <ErrorFallback
            error={error}
            title={t("error.GENERIC")}
            message={t("error.FEATURED_FETCH_ERROR")}
          />
        </div>
      </div>
    </Card>
  )
}
