import { useState } from "react"
import { useCurrentUserContext } from "../AppShell/CurrentUserProvider"
import { Chat } from "./components/Chat"
import { usePostFlowiseQuestionStream } from "./hooks/useFlowiseQuestionStream"

export type ChatMessage = {
  messageType: "Question" | "Response" | "Error"
  text: string
}

// TODO: chat and suggestions should be dynamic
const flowId = "91c85855-e251-4a27-ab9c-5199e4da54ca" // Hard coded to Attensi University, for testing. Should be dynamic based on company in the future
const AttensiUniversityQuestionSuggestions = [
  "How do I create great minigames?",
  "How do I create a Fast course?",
  "What is Attensi Insights?",
  "How do I write dialogues?",
]

export const AIChat = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean
  onClose: () => void
}) => {
  const [messages, setMessages] = useState<Array<ChatMessage>>([])
  const { isLoading, askQuestion } = usePostFlowiseQuestionStream(flowId)
  const { currentUser } = useCurrentUserContext()

  const handleSubmit = async (question: string) => {
    // Append the question - and a response that we can update while streaming
    setMessages((prevMessages) => [
      ...prevMessages,
      { text: question, messageType: "Question" },
      { text: "", messageType: "Response" },
    ])

    await askQuestion(
      question,
      (chunk) => {
        // Append the incoming stream chunks of text to the current chat response
        setMessages((prevMessages) => {
          const messagesCopy = [...prevMessages]
          const indexOfLastMessage = messagesCopy.length - 1
          const lastMessageCopy = messagesCopy[indexOfLastMessage]

          if (!lastMessageCopy) return prevMessages
          messagesCopy.splice(indexOfLastMessage, 1, {
            messageType: "Response",
            text: lastMessageCopy.text + chunk.text,
          })

          return messagesCopy
        })
      },
      (errorMessage) => {
        // Append error message as a special (error) chat response
        setMessages((prevMessages) => [
          ...prevMessages,
          { text: errorMessage, messageType: "Error" },
        ])
      }
    )
  }

  return (
    <Chat
      isOpen={isOpen}
      onClose={onClose}
      userAvatar={currentUser.avatar}
      questionSuggestions={AttensiUniversityQuestionSuggestions}
      onSubmit={handleSubmit}
      messages={messages}
      isPendingAnswer={isLoading}
    />
  )
}
