import avatar from "assets/demo/avatar.svg"
import portalBackground from "assets/demo/portalBackground.svg"
import { mockData, registerMockData, type ResultOf } from "graphql-mock"
import { graphql } from "../../graphql"
import { mockNavigationData, navigationFragment } from "./Navigation/gql"

export type Data = ResultOf<typeof query>

export const query = graphql(
  `
    query AppShell {
      currentUser {
        avatar
        avatarTitle
      }
      currentCompany {
        portalFeatureFlags
        portalBackgroundUrl
        enabledApps
      }
      navigationCompany: currentCompany {
        ...Navigation
      }
    }
  `,
  [navigationFragment]
)

export const mockAppShellData = mockData<Data>({
  currentUser: {
    avatar: avatar,
    avatarTitle: "Avatar description here",
  },
  currentCompany: {
    portalBackgroundUrl: portalBackground,
    portalFeatureFlags: ["enable_portal_background"],
    enabledApps: ["portal"],
  },
  navigationCompany: mockNavigationData(),
})

registerMockData(query, mockAppShellData())
