import { Card, useErrorSentryLog } from "materia"
import { useTranslation } from "react-i18next"
import s from "./styles.module.scss"

export const EventWidgetError = ({ error }: { error: unknown }) => {
  // TODO [sentry-logging] https://jira.attensi.com/browse/WEB-18745
  // Find a better place to send Sentry logs from
  useErrorSentryLog(error)
  const { t } = useTranslation()

  return (
    <Card borderRadius="24">
      <div className={s.widget}>
        <div className={s.widget__header}>
          <h2 className={s.widget__text}>{t("event.UPCOMING_EVENTS")}</h2>
        </div>
        <p className={s.widget__error}>{t("error.EVENT_WIDGET_FETCH_ERROR")}</p>
      </div>
    </Card>
  )
}
