import chevronIcon from "assets/icons/chevronIcon.svg"
import { useGraphqlQuery } from "graphql-mock"
import { ProgressBar } from "materia"
import { ErrorPopup } from "utility-components"
import { Stars } from "../../../components/Stars"
import { useNavigateBack } from "../../../tools/useNavigateBack"
import { query } from "./gql"
import { BundleHeaderLoading } from "./loading"
import s from "./styles.module.scss"

export const BundleHeader = ({ id }: { id: string }) => {
  const { data, error, isPending } = useGraphqlQuery(query, { id })

  const navigateBack = useNavigateBack()

  if (isPending) return <BundleHeaderLoading />
  if (error) return <ErrorPopup error={error} />

  const { progress, title } = data.productBundle
  const noScoreBundleBadge = !progress.starsCount && !progress.starsMax

  return (
    <div className={s.header}>
      <div className={s.header__centered}>
        <div className={s.header__widgetLayoutSize}>
          <button className={s.header__back} onClick={() => navigateBack()}>
            <img className={s.header__backIcon} src={chevronIcon} alt="" />
          </button>
          <div className={s.header__title}>{title}</div>
          {!noScoreBundleBadge && (
            <>
              <div className={s.header__progress}>
                <ProgressBar
                  value={progress.certifiedCount}
                  maxValue={progress.certifiedMax}
                  color="purple-60"
                  backgroundColor="purple-10"
                />
              </div>
              <div className={s.header__stars}>
                <Stars count={1} max={1} size="extraSmall" />
                {progress.starsCount}/{progress.starsMax}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}
