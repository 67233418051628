import { ErrorFallback } from "materia"
import { useTranslation } from "react-i18next"
import s from "./styles.module.scss"

export const RecentActivityWidgetModalError = ({
  error,
}: {
  error: unknown
}) => {
  const { t } = useTranslation()
  return (
    <div className={s.error}>
      <ErrorFallback
        error={error}
        title={t("error.GENERIC")}
        message={t("error.RECENT_ACTIVITY_FETCH_ERROR")}
      />
    </div>
  )
}
