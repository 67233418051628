import { formatNumber } from "format"
import { leaderboard } from "leaderboard-api"
import { useTranslation } from "react-i18next"
import { useGetOrdinal } from "../../../dataUtilities/getOrdinal"
import { Badge } from "../../Badge"
import { type Data } from "../gql"

import s from "./styles.module.scss"

export const ProductCardLeaderboardsDetails = ({ data }: { data: Data }) => {
  const { progress, id } = data
  const highScore = progress.highScore
  const isRanked = highScore > 0

  return (
    <div className={s.leaderboardsDetails}>
      <UserHighScore highScore={highScore} />
      <UserRank isRanked={isRanked} productId={Number(id)} />
    </div>
  )
}

type UserHighScoreProps = { highScore: number }
const UserHighScore = ({ highScore }: UserHighScoreProps) => {
  const { t, i18n } = useTranslation()

  const scoreFormatted = formatNumber(highScore, i18n.language)
  const scoreText = `${scoreFormatted} ${t("product.SCORE")}`

  const noPoints = highScore === 0

  const badgeType = noPoints ? "scoreNoPoints" : "score"

  return <Badge title={scoreText} type={badgeType} size="small" />
}

type UserRankProps = {
  isRanked: boolean
  productId: number
}
const UserRank = ({ isRanked, productId }: UserRankProps) => {
  const { t } = useTranslation()
  const getOrdinal = useGetOrdinal()

  const { data: rankResponse, isError } = leaderboard.getRank.useQuery(
    [productId],
    // TODO [error-handling-effort] https://jira.attensi.com/browse/WEB-18497
    // When the task above is done, we'll remove throwOnError
    { throwOnError: false }
  )

  if (isError)
    return (
      <div className={s.leaderboardsDetails__rank}>
        {t("product.UNKNOWN_RANK")}
      </div>
    )

  const rank = isRanked ? rankResponse?.rank : undefined
  const rankText = rank ? `${getOrdinal(rank)} ${t("product.RANK_UNIT")}` : ""

  return <div className={s.leaderboardsDetails__rank}>{rankText}</div>
}
