import closeIcon from "assets/icons/closeIcon.svg"
import { DiplomaPreview, DiplomaPreviewError } from "fiesta-components"
import { useGraphqlQuery } from "graphql-mock"
import { useModal } from "hooks"
import { Button, Dialog, Loader, Modal } from "materia"
import type { ReactNode } from "react"
import { useTranslation } from "react-i18next"
import { getProductLink } from "../../dataUtilities/productDataUtilities"
import { useCurrentCompanyContext } from "../AppShell/CurrentCompanyProvider"
import { useCurrentUserContext } from "../AppShell/CurrentUserProvider"
import { query, type Data } from "./gql"
import { DiplomaModalLoading } from "./loading"
import { useDownloadDiploma } from "./useDownloadDiploma"

import s from "./styles.module.scss"

type DiplomaModalProps = { productId: string; closeRoute: string }
export const DiplomaModal = ({ productId, closeRoute }: DiplomaModalProps) => {
  const { onClose } = useModal(closeRoute)

  const { data, error, isError, isPending, isSuccess } = useGraphqlQuery(
    query,
    {
      productId,
    }
  )

  const {
    isAutoDownload,
    isAutoDownloadFinished,
    isDownloadInProgress,
    printRef,
    onDownload,
    onAutoDownloadClose,
  } = useDownloadDiploma(productId, isSuccess)

  const isUserCertifiedForProduct = Boolean(data?.product.progress.certified)

  return (
    <>
      <DiplomaModalWrapper
        onClose={onClose}
        onDownload={onDownload}
        isDownloadInProgress={isDownloadInProgress}
        isUserCertifiedForProduct={isUserCertifiedForProduct}
      >
        {isError && <DiplomaPreviewError error={error} />}
        {isPending && <DiplomaModalLoading />}
        {isSuccess && (
          <PrintableDiploma
            product={data.product}
            closeRoute={closeRoute}
            printRef={printRef}
          />
        )}
      </DiplomaModalWrapper>

      {isAutoDownload && (
        <AutoDownloadScreen
          isAutoDownloadInProgress={isPending || !isAutoDownloadFinished}
          onClose={onAutoDownloadClose}
        />
      )}
    </>
  )
}

const DiplomaModalWrapper = ({
  children,
  isUserCertifiedForProduct,
  isDownloadInProgress,
  onClose,
  onDownload,
}: {
  children: ReactNode
  isUserCertifiedForProduct: boolean
  isDownloadInProgress: boolean
  onClose: () => void
  onDownload: () => void
}) => {
  const { t } = useTranslation()
  return (
    <Dialog className={s.diplomaModal} onClose={onClose} ariaLabel="Diploma">
      <div className={s.diplomaModal__content}>
        <div className={s.diplomaModal__shell}>
          <CloseButton onClose={onClose} />
          {children}
        </div>
        {isUserCertifiedForProduct && (
          <Button onClick={onDownload} isLoading={isDownloadInProgress}>
            {t("diploma.DOWNLOAD")}
          </Button>
        )}
      </div>
    </Dialog>
  )
}

type PrintableDiplomaProps = {
  product: Data["product"]
  closeRoute: string
  printRef: React.RefObject<HTMLDivElement>
}
const PrintableDiploma = ({
  product,
  closeRoute,
  printRef,
}: PrintableDiplomaProps) => {
  const { i18n } = useTranslation()
  const { currentUser } = useCurrentUserContext()
  const { currentCompany } = useCurrentCompanyContext()
  const { diplomaConfiguration } = product

  if (diplomaConfiguration === null) throw new Error("Diploma does not exist.")

  const productLink = `${closeRoute}/${getProductLink(
    product.id,
    product.productType
  )}`

  return (
    <div ref={printRef}>
      <DiplomaPreview
        // DATA
        title={diplomaConfiguration.title ?? ""}
        description={diplomaConfiguration.description ?? ""}
        productTitle={product.title ?? ""}
        issueDate={product.diplomaProgress?.certifiedAt ?? ""}
        expirationDate={product.deadlineOccurrence?.deadlineDate ?? ""}
        firstName={currentUser.firstName ?? ""}
        lastName={currentUser.lastName ?? ""}
        companyName={currentCompany.name ?? ""}
        companyLogo={currentCompany.logoUrl}
        // CONFIG
        showUserLastName={diplomaConfiguration.showUserLastName}
        showIssueDate={diplomaConfiguration.showIssueDate}
        showProductTitle={diplomaConfiguration.showProductTitle}
        showCompanyName={diplomaConfiguration.showCompanyName}
        showCompanyLogo={diplomaConfiguration.showCompanyLogo}
        showDiplomaDescription={diplomaConfiguration.showDiplomaDescription}
        // META
        isUnlocked={product.progress.certified}
        contentLocale={i18n.language}
        productLink={productLink}
      />
    </div>
  )
}

const AutoDownloadScreen = ({
  isAutoDownloadInProgress,
  onClose,
}: {
  isAutoDownloadInProgress: boolean
  onClose: () => void
}) => {
  const { t } = useTranslation()

  const downloadText = isAutoDownloadInProgress
    ? t("diploma.AUTODOWNLOAD_PENDING")
    : t("diploma.AUTODOWNLOAD_DONE")

  return (
    <Modal onClose={onClose} ariaLabel="Downloading your diploma">
      <div className={s.autodownload}>
        {isAutoDownloadInProgress && <Loader />}
        <span className={s.autodownload__text}>{downloadText}</span>
        {!isAutoDownloadInProgress && (
          <Button onClick={onClose}>{t("actions.BACK_TO_PORTAL")}</Button>
        )}
      </div>
    </Modal>
  )
}

export const CloseButton = ({ onClose }: { onClose: () => void }) => (
  <div className={s.diplomaModal__closeButton}>
    <button className={s.closeButton} onClick={onClose} aria-label="Close">
      <img className={s.closeButton__icon} src={closeIcon} alt="" />
    </button>
  </div>
)
