import { ReactComponent as PlayButtonIcon } from "assets/icons/playButton.svg"
import { useGraphqlQuery } from "graphql-mock"
import { ProgressCircle } from "materia"
import { ATTENSI_PRODUCT_PLATFORM_FOR_OS } from "../../constants"
import { addPlayLinkParams } from "../../tools/addPlayLinkParams"
import { sendOpenProductMessage } from "../../vuplex/sendOpenProductMessage"
import { useVuplexStore } from "../../vuplex/vuplexStore"
import { Stars } from "../Stars"
import { query, type Data } from "./gql"
import s from "./styles.module.scss"

export const ProductMiniModal = () => {
  const { productId, miniModalIsOpen } = useVuplexStore()

  const { data, error, isPending } = useGraphqlQuery(
    query,
    {
      productId: String(productId!),
      platform: ATTENSI_PRODUCT_PLATFORM_FOR_OS,
    },
    { enabled: productId !== undefined && miniModalIsOpen }
  )

  if (!miniModalIsOpen) return
  if (isPending) return
  if (error) {
    // The productId is stored in Skills indefinitely.
    // The product can be deleted or updated since then, and it might not be available anymore.
    // Therefore we just ignore the error.
    return
  }

  const { product } = data
  const { image, title } = product
  const { starsCount, starsMax } = data.product.progress

  return (
    <button className={s.productMiniModal} onClick={() => openProduct(data)}>
      <img className={s.image} src={image} alt="" />
      <div className={s.column}>
        <h2 className={s.title}>{title}</h2>
        <div
          className={s.stars}
          aria-label={`${starsCount} of ${starsMax} stars`}
        >
          <Stars count={starsCount} max={starsMax} size="extraSmall" />
        </div>
      </div>
      <Play data={data} />
    </button>
  )
}

const Play = ({ data }: { data: Data }) => {
  const { starsCount, starsMax } = data.product.progress
  return (
    <div className={s.play}>
      <div className={s.play__progress}>
        <ProgressCircle
          value={starsCount}
          maxValue={starsMax}
          variant="coal"
          backgroundColor="marble"
          stroke={11}
        />
      </div>
      <PlayButtonIcon className={s.play__playIcon} />
    </div>
  )
}

const openProduct = async (data: Data) => {
  const { product } = data
  const link = await addPlayLinkParams({
    link: product.playLinks.appLink,
    useTransferToken: product.useTransferToken,
  })
  if (link) sendOpenProductMessage(product, link)
}
