import { ReactComponent as CheckmarkCircleGreen } from "assets/icons/checkmarkCircleGreen.svg"
import { ReactComponent as ChevronIcon } from "assets/icons/chevronIcon.svg"
import { useTranslation } from "react-i18next"
import { FiestaLink } from "utility-components"
import { ProductCardShell } from "../ProductCard/shell"
import { Stars } from "../Stars"
import type { Data } from "./gql"
import s from "./styles.module.scss"

export const ProductBundleCard = ({ data }: { data: Data }) => {
  const { id, products, progress, title } = data
  const { t } = useTranslation()

  const countText = t("bundle.products", {
    count: products.pageInfo.totalItemCount,
  })

  const images = products.nodes.map((product) => product.image)
  const noScoreBundleBadge = !progress.starsCount && !progress.starsMax
  const isCheckmarkVisible = progress.certified && !noScoreBundleBadge

  return (
    <li>
      <ProductCardShell>
        <FiestaLink to={`/bundle/${id}`} className={s.bundle}>
          {isCheckmarkVisible && <Certified />}
          <div className={s.bundle__images}>
            {images.map((image, index) => (
              <img key={index} className={s.bundle__image} src={image} alt="" />
            ))}
          </div>
          <div className={s.bundle__container}>
            <div className={s.bundle__count}>{countText}</div>
            <div className={s.bundle__title}>{title}</div>
            <div className={s.bundle__bottom}>
              {noScoreBundleBadge ? (
                <span />
              ) : (
                <div className={s.bundle__badge}>
                  <Stars count={1} max={1} size="extraSmall" />
                  {progress.starsCount}/{progress.starsMax}
                </div>
              )}
              <ChevronIcon className={s.bundle__chevronIcon} />
            </div>
          </div>
        </FiestaLink>
      </ProductCardShell>
    </li>
  )
}

const Certified = () => <CheckmarkCircleGreen className={s.certified} />
