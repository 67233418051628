import { Dialog, ErrorFallback } from "materia"
import { useTranslation } from "react-i18next"
import { CloseButton } from "."
import s from "./styles.module.scss"

export const ProductModalError = ({
  error,
  onClose,
}: {
  error: unknown
  onClose: () => void
}) => {
  const { t } = useTranslation()

  return (
    <Dialog className={s.modal} ariaLabel="Play product" onClose={onClose}>
      <div className={s.content}>
        <div className={s.main}>
          <div className={s.error}>
            <ErrorFallback
              error={error}
              title={t("error.GENERIC")}
              message={t("error.PRODUCT_MODAL_FETCH_ERROR")}
            />
          </div>
          <CloseButton onClick={onClose} />
        </div>
      </div>
    </Dialog>
  )
}
