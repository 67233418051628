import { auth } from "auth"
import { useToggle } from "hooks"
import { Dropdown, useWindowWidth } from "materia"
import { useState, type ComponentProps } from "react"
import { useTranslation } from "react-i18next"
import { type FiestaLinkProps } from "utility-components"
import { ATTENSI_PRODUCT_PLATFORM_FOR_OS } from "../../../constants"
import { getLocaleDisplayName } from "../../../dataUtilities/getLocaleDisplayName"
import { getPreferredUpload } from "../../../dataUtilities/productDataUtilities"
import { createPlaythrough } from "../../../graphql/createPlaythrough"
import { addPlayLinkParams } from "../../../tools/addPlayLinkParams"
import { APP_TYPE, IS_APP } from "../../../vuplex/constants"
import { isHandledByVuplex } from "../../../vuplex/isHandledByVuplex"
import { sendOpenProductMessage } from "../../../vuplex/sendOpenProductMessage"
import { sendVuplexMessage } from "../../../vuplex/sendVuplexMessage"
import { LauncherOverlay } from "../LauncherOverlay"
import { ProductModalDownload } from "../ProductModalDownload"
import { ProductModalHelpLoader } from "../ProductModalHelp/ProductModalHelpLoader"
import { ProductModalMain } from "../ProductModalMain"
import type { Data } from "./gql"

export const ProductModalButtons = ({ data }: { data: Data }) => {
  const { productType } = data

  switch (productType) {
    case "skill":
    case "behavior":
    case "operations":
    case "process":
    case "workflow":
    case "act":
    case "real_talk":
      return <PlayableProductButtons data={data} />
    case "app":
      return <AppProductButtons data={data} />
    case "fast":
    case "assessment":
    case "process_on_web":
    case "quiz_race":
      return <WebGamesProductButton data={data} />
    case "file":
      return <FileProductButton data={data} />
    case "link":
      return <LinkProductButton data={data} />
    case "pdf":
      return <PdfProductButton data={data} />
    case "video":
      return <VideoProductButton data={data} />
    case "xapi":
      return <XapiProductButton data={data} />
  }
}

const XapiProductButton = ({ data }: { data: Data }) => {
  const { t } = useTranslation()

  const { contentLink, scormStrictScoring, openExternalBrowser } = data

  const openInModal = scormStrictScoring && !openExternalBrowser

  const linkProps: Partial<PlayLinkProps> = openInModal
    ? { to: "play" }
    : {
        target: "_blank",
        getLink: () => auth.addAccessParams(contentLink),
      }

  return (
    <PlayLink data={data} {...linkProps}>
      {t("product.PLAY_TEXT_SIM")}
    </PlayLink>
  )
}

const VideoProductButton = ({ data }: { data: Data }) => {
  const { t } = useTranslation()
  const { localeContentLinks } = data
  const links = localeContentLinks.nodes

  const { selectedLink, dropdown } = useLocalizedLinkDropdown(links)
  const locale = selectedLink?.locale

  return (
    <>
      {dropdown}
      <PlayLink
        data={data}
        to="play"
        search={(search) => ({ ...search, content_locale: locale })}
      >
        {t("product.PLAY_TEXT_VIDEO")}
      </PlayLink>
    </>
  )
}

const WebGamesProductButton = ({ data }: { data: Data }) => {
  const { t } = useTranslation()
  const { contentLink, openExternalBrowser, productType } = data

  const openExternalLink = async () => {
    const link = await auth.addAccessParams(contentLink)

    sendVuplexMessage({
      type: "OPEN_LINK",
      payload: { link, openExternalBrowser: true },
    })
  }

  if (IS_APP && openExternalBrowser) {
    return (
      <ProductModalMain.PlayLink onClick={openExternalLink}>
        {t("product.PLAY_TEXT_SIM")}
      </ProductModalMain.PlayLink>
    )
  }

  const onClick = () => {
    if (productType === "quiz_race") {
      createPlaythrough(data.id)
    }
  }

  return (
    <PlayLink data={data} to="play" onClick={onClick}>
      {t("product.PLAY_TEXT_SIM")}
    </PlayLink>
  )
}

const PdfProductButton = ({ data }: { data: Data }) => {
  const { t } = useTranslation()
  const { id, productUploads, contentLink } = data
  const uploads = productUploads.nodes

  const { selectedLink, dropdown } = useLocalizedLinkDropdown(uploads)
  const locale = selectedLink?.locale

  const { isSmallWindow } = useWindowWidth()
  const pdfSrc = getPreferredUpload({ contentLink, locale, productUploads })

  const playLinkProps: FiestaLinkProps =
    isSmallWindow || IS_APP
      ? {
          href: pdfSrc,
          target: "_blank",
          onClick: () => createPlaythrough(id),
        }
      : {
          to: "play",
          search: (search) => ({ ...search, content_locale: locale }),
          onClick: () => createPlaythrough(id),
        }

  return (
    <>
      {dropdown}
      <PlayLink data={data} {...playLinkProps}>
        {t("product.PLAY_TEXT_PDF")}
      </PlayLink>
    </>
  )
}

const FileProductButton = ({ data }: { data: Data }) => {
  const { t } = useTranslation()
  const { id, contentLink, productUploads } = data
  const uploads = productUploads.nodes

  const { selectedLink, dropdown } = useLocalizedLinkDropdown(uploads)

  return (
    <>
      {dropdown}
      <PlayLink
        data={data}
        href={selectedLink?.url ?? contentLink}
        iconVariant="download"
        target="_blank"
        onClick={() => createPlaythrough(id)}
      >
        {t("product.DOWNLOAD_TEXT_SIM")}
      </PlayLink>
    </>
  )
}

const LinkProductButton = ({ data }: { data: Data }) => {
  const { t } = useTranslation()

  const { localeContentLinks, contentLink } = data
  const links = localeContentLinks.nodes
  const { selectedLink, dropdown } = useLocalizedLinkDropdown(links)

  const link = selectedLink?.link ?? contentLink

  // This is specifically for linking to Discourse forums
  // https://jira.attensi.com/browse/WEB-15665
  const isDiscourseLink =
    link === "https://api.attensi.com/api/public/discourse/callback"

  const getDiscourseLink = () => {
    return auth.addAccessParams(link)
  }

  return (
    <>
      {dropdown}
      <PlayLink
        data={data}
        target="_blank"
        href={isDiscourseLink ? undefined : link}
        getLink={isDiscourseLink ? getDiscourseLink : undefined}
        onClick={() => createPlaythrough(data.id)}
      >
        {t("product.PLAY_TEXT_LINK")}
      </PlayLink>
    </>
  )
}

const PlayableProductButtons = ({ data }: { data: Data }) => {
  const { t } = useTranslation()

  const [showLauncherOverlay, toggleLauncherOverlay] = useToggle()
  const [showHelpModal, toggleHelpModal] = useToggle()
  const [showDownloadHelpModal, toggleDownloadHelpModal] = useToggle()

  const { playLinks, hasVppCodes, useTransferToken } = data
  const { appLink, appStoreLink, launcherLink, webglLink } = playLinks

  const canPlay = webglLink || appLink || launcherLink

  if (!canPlay) {
    return <NotSupported data={data} />
  }

  const getLink = (link: string | null) => {
    return addPlayLinkParams({ link, useTransferToken })
  }

  const showVpp = hasVppCodes && ATTENSI_PRODUCT_PLATFORM_FOR_OS === "iOS"
  const showInstallApp = !showVpp && APP_TYPE !== "skills"

  const showDownloadHelpButton = showInstallApp && appStoreLink && appLink
  const hasMultiplePlayButtons = launcherLink && webglLink
  const webglLinkProps: Partial<PlayLinkProps> = data.webglResponsive
    ? { to: "play" }
    : {
        target: "_blank",
        getLink: () => getLink(webglLink),
      }

  return (
    <>
      {showVpp && (
        <PlayLink data={data} iconVariant="download" to="vpp">
          {t("product.DOWNLOAD_TEXT_SIM")}
        </PlayLink>
      )}

      {showInstallApp && appStoreLink && (
        <PlayLink
          data={data}
          href={appStoreLink}
          target="_blank"
          iconVariant="download"
        >
          {t("product.DOWNLOAD_TEXT_SIM")}
        </PlayLink>
      )}

      {appLink && (
        <PlayLink data={data} getLink={() => getLink(appLink)}>
          {t("product.PLAY_TEXT_SIM")}
        </PlayLink>
      )}

      {webglLink && (
        <PlayLink data={data} {...webglLinkProps}>
          {hasMultiplePlayButtons
            ? t("content.PRODUCT_WEBGL_LAUNCHER")
            : t("product.PLAY_TEXT_SIM")}
        </PlayLink>
      )}

      {launcherLink && (
        <PlayLink
          data={data}
          onClick={toggleLauncherOverlay}
          linkVariant={hasMultiplePlayButtons ? "ghost" : "theme"}
          getLink={() => getLink(launcherLink)}
        >
          {hasMultiplePlayButtons
            ? t("content.PRODUCT_DESKTOP_LAUNCHER")
            : t("product.PLAY_TEXT_SIM")}
        </PlayLink>
      )}

      {hasMultiplePlayButtons && (
        <ProductModalMain.HelpIcon onClick={toggleHelpModal} />
      )}

      {showHelpModal && <ProductModalHelpLoader onToggle={toggleHelpModal} />}

      {showDownloadHelpButton && (
        <ProductModalMain.HelpIcon onClick={toggleDownloadHelpModal} />
      )}

      {showDownloadHelpModal && (
        <ProductModalDownload onToggle={toggleDownloadHelpModal} />
      )}

      {showLauncherOverlay && (
        <LauncherOverlay onBack={toggleLauncherOverlay} />
      )}
    </>
  )
}

const AppProductButtons = ({ data }: { data: Data }) => {
  const { t } = useTranslation()
  const { id, contentLink, playLinks } = data

  const { appStoreLink } = playLinks

  if (!appStoreLink) {
    return <NotSupported data={data} />
  }

  const vuplexCanHandleDownload = IS_APP && Boolean(contentLink)
  const showDownloadLink = appStoreLink && !vuplexCanHandleDownload

  return (
    <>
      {showDownloadLink && (
        <PlayLink
          data={data}
          href={appStoreLink}
          target="_blank"
          iconVariant="download"
          onClick={() => createPlaythrough(id)}
        >
          {t("product.DOWNLOAD_TEXT_SIM")}
        </PlayLink>
      )}

      {contentLink && (
        <PlayLink
          data={data}
          href={contentLink}
          target="_blank"
          onClick={() => createPlaythrough(id)}
        >
          {t("product.PLAY_TEXT_SIM")}
        </PlayLink>
      )}
    </>
  )
}

const NotSupported = ({ data }: { data: Data }) => {
  const { t } = useTranslation()
  const title = `${t(
    "product.NOT_SUPPORTED_GENERIC"
  )} ${ATTENSI_PRODUCT_PLATFORM_FOR_OS}`
  const platforms = data.supportedPlatforms.join(" / ")

  return (
    <ProductModalMain.NotSupported
      title={title}
      supportedBy={t("product.SUPPORTED_PLATFORMS")}
      platforms={platforms}
    />
  )
}

type PlayLinkProps = ComponentProps<typeof ProductModalMain.PlayLink> & {
  data: Data
  getLink?: () => Promise<string | undefined>
}
const PlayLink = ({
  data,
  getLink,
  onClick,
  href,
  ...props
}: PlayLinkProps) => {
  const { productType } = data

  const shouldDelegateToVuplex = IS_APP && isHandledByVuplex(productType)

  const handleClick = async () => {
    onClick?.()

    const dynamicLink = await getLink?.()

    if (shouldDelegateToVuplex) {
      if (dynamicLink) {
        sendOpenProductMessage(data, dynamicLink)
      } else if (href) {
        sendOpenProductMessage(data, href)
      }
    } else if (dynamicLink) {
      if (props.target === "_blank") {
        window.open(dynamicLink)
      } else {
        window.location.href = dynamicLink
      }
    }
  }

  return (
    <ProductModalMain.PlayLink
      {...props}
      replace={true}
      onClick={handleClick}
      href={shouldDelegateToVuplex ? undefined : href}
    />
  )
}

const useLocalizedLinkDropdown = <T extends { locale: string }>(
  localeLinks: T[]
) => {
  const { i18n } = useTranslation()

  const preferredLink = localeLinks.find(
    (localeLink) => localeLink.locale === i18n.language
  )
  const [selectedLink, setSelectedLink] = useState(
    preferredLink ?? localeLinks[0]
  )

  const options = localeLinks.map((localeLink, index) => (
    <Dropdown.Option key={index} value={localeLink}>
      {getLocaleDisplayName(localeLink.locale)}
    </Dropdown.Option>
  ))

  const dropdown = selectedLink && !preferredLink && (
    <ProductModalMain.LanguageDropdown>
      <Dropdown value={selectedLink} onChange={setSelectedLink}>
        <Dropdown.Button>
          {getLocaleDisplayName(selectedLink.locale)}
        </Dropdown.Button>
        <Dropdown.Options>{options}</Dropdown.Options>
      </Dropdown>
    </ProductModalMain.LanguageDropdown>
  )

  return { selectedLink, dropdown }
}
