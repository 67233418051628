import type { TypedDocumentNode } from "@graphql-typed-document-node/core"
import { createContext } from "react"

type QueryMap = WeakMap<TypedDocumentNode<any, any>, any>

export const mockMap: QueryMap = new WeakMap()
export const MockInputMapContext = createContext<QueryMap | undefined>(
  undefined
)

export type ForceStateValue = "loading" | "live" | "mock" | "error"
export const ForceStateContext = createContext<ForceStateValue>("live")
