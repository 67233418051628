import closeIcon from "assets/icons/closeIcon.svg"
import { Modal } from "materia"
import type { PropsWithChildren, ReactNode } from "react"
import s from "./ProductModalHelp.module.scss"

type ProductModalHelpProps = {
  children: ReactNode
  onClose: () => void
}
export const ProductModalHelp = ({
  onClose,
  children,
}: ProductModalHelpProps) => (
  <Modal onClose={onClose} ariaLabel="How to play product">
    <div className={s.productModalHelp}>{children}</div>
  </Modal>
)

type ItemsProps = {
  title: string
  children: ReactNode
}
const Items = ({ title, children }: ItemsProps) => (
  <div className={s.items}>
    <div className={s.items__title}>{title}</div>
    <ul className={s.items__description}>{children}</ul>
  </div>
)

const Item = ({ children }: PropsWithChildren) => (
  <li className={s.item}>{children}</li>
)

type LinkProps = {
  href: string
  text: string
}
const Link = ({ href, text }: LinkProps) => (
  <a href={href} className={s.link}>
    {text}
  </a>
)

type CloseButtonProps = {
  onClick: () => void
}
const CloseButton = ({ onClick }: CloseButtonProps) => (
  <button className={s.closeButton} onClick={onClick} aria-label="Close">
    <img className={s.closeButton__icon} src={closeIcon} alt="" />
  </button>
)

ProductModalHelp.Items = Items
ProductModalHelp.Item = Item
ProductModalHelp.Link = Link
ProductModalHelp.CloseButton = CloseButton
