import { ReactComponent as AIIcon } from "assets/icons/aiChatIcon.svg"
import { ReactComponent as AlertIcon } from "assets/icons/alertIcon.svg"
import { ReactComponent as ArrowRightIcon } from "assets/icons/arrowRight.svg"
import closeIcon from "assets/icons/closeIcon.svg"
import GraduationHat from "assets/images/graduationHat.png"
import { Modal } from "materia"
import { useState, useRef, type KeyboardEventHandler, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { modifiers } from "ui-tools"
import { Avatar } from "../../../Avatar"
import type { ChatMessage } from "../../index"
import s from "./styles.module.scss"

export const Chat = ({
  isOpen,
  onClose,
  userAvatar,
  questionSuggestions,
  onSubmit,
  messages,
  isPendingAnswer,
}: {
  isOpen: boolean
  onClose: () => void
  userAvatar: string
  questionSuggestions: string[] | undefined
  onSubmit: (question: string) => void
  messages: ChatMessage[]
  isPendingAnswer: boolean
}) => {
  const { t } = useTranslation()
  const [question, setQuestion] = useState("")
  const messagesScrollContainerRef = useRef<HTMLDivElement | null>(null)
  const isChatEmpty = messages.length === 0

  // Keep chat scrolled to bottom on incoming or outgoing message
  useEffect(() => {
    if (!messagesScrollContainerRef.current) return

    messagesScrollContainerRef.current.scrollTop =
      messagesScrollContainerRef.current.scrollHeight
  }, [messages])
  if (!isOpen) return null

  const handleSubmitQuestion = () => {
    const trimmedQuestion = question.trim()
    const isNoQuestionPresent = trimmedQuestion.length === 0

    if (isPendingAnswer || isNoQuestionPresent) return

    onSubmit(trimmedQuestion)
    setQuestion("")
  }

  return (
    <Modal
      ariaLabel="AI chat window"
      onClose={onClose}
      isFullScreenMobile={true}
    >
      <div className={s.aiChat}>
        <div className={s.aiChat__topBar}>
          <div className={s.avatarWrapper}>
            <Avatar size="medium" image={GraduationHat} alt="" />
            <span className={s.aiChat__title}>{t("chat.TITLE")}</span>

            <BetaTag />
          </div>

          <CloseButton onClose={onClose} />
        </div>

        <div ref={messagesScrollContainerRef} className={s.aiChat__main}>
          {isChatEmpty && (
            <ChatIntro
              questionSuggestions={questionSuggestions}
              onClickSuggestion={(question) => onSubmit(question)}
            />
          )}

          {messages.map((message, index) => {
            const isLastMessage = messages.length - 1 === index
            if (message.messageType === "Question") {
              // Get avatars and descriptions from BE
              return (
                <ChatQuestion
                  key={index}
                  text={message.text}
                  avatar={userAvatar}
                />
              )
            } else {
              return (
                <ChatResponse
                  key={index}
                  text={message.text}
                  isLoading={isLastMessage && isPendingAnswer}
                  avatar={GraduationHat}
                  isError={message.messageType === "Error"}
                />
              )
            }
          })}
        </div>

        <div className={s.aiChat__footer}>
          <ChatTextInput
            value={question}
            onChange={(event) => setQuestion(event.target.value)}
            onSubmit={handleSubmitQuestion}
          />
        </div>
      </div>
    </Modal>
  )
}

const BetaTag = () => {
  return <span className={s.betaTag}>BETA</span>
}

const ChatIntro = ({
  questionSuggestions,
  onClickSuggestion,
}: {
  questionSuggestions: string[] | undefined
  onClickSuggestion: (question: string) => void
}) => {
  const { t } = useTranslation()
  const isQuestionSuggestions =
    questionSuggestions !== undefined && questionSuggestions.length > 0

  return (
    <div className={s.chatIntro}>
      <div className={s.chatIntro__header}>
        <AIIcon className={s.chatIntro__icon} />
        <span>{t("chat.INTRO_MESSAGE")}</span>
      </div>

      {isQuestionSuggestions && (
        <div className={s.questionSuggestions}>
          {questionSuggestions.map((question, index) => (
            <button
              onClick={() => onClickSuggestion(question)}
              className={s.questionSuggestions__suggestion}
              key={index}
            >
              {question}
            </button>
          ))}
        </div>
      )}
    </div>
  )
}

const ChatTextInput = ({
  value,
  onChange,
  onSubmit,
}: {
  value: string
  onChange: React.ChangeEventHandler<HTMLTextAreaElement>
  onSubmit: () => void
}) => {
  const { t } = useTranslation()
  const textAreaRef = useRef<HTMLTextAreaElement | null>(null)

  // Let chat input dynamically increase and decrease in height as the input is filled up
  const adjustTextAreaHeight = () => {
    if (!textAreaRef.current?.style) return

    // Allow text area to shrink in height text content is reduced
    textAreaRef.current.style.height = "auto"
    // Allow text area to grow in height as text content is increased
    textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`
    textAreaRef.current.rows = 1
  }

  useEffect(() => {
    adjustTextAreaHeight()
  }, [value])

  const handleKeyDown: KeyboardEventHandler = (event) => {
    if (event.key === "Enter") {
      event.preventDefault()
      event.stopPropagation()
      onSubmit()
    }
  }

  return (
    <div className={s.chatTextInput}>
      <textarea
        ref={textAreaRef}
        className={s.chatTextInput__input}
        placeholder={t("chat.MESSAGE")}
        value={value}
        onKeyDown={handleKeyDown}
        onInput={adjustTextAreaHeight}
        rows={1}
        onChange={onChange}
      />

      <button
        type="submit"
        className={s.chatTextInput__submitButton}
        onClick={onSubmit}
        aria-label="submit question"
      >
        <ArrowRightIcon />
      </button>
    </div>
  )
}

const ChatQuestion = ({ text, avatar }: { text: string; avatar: string }) => {
  return (
    <div className={s.chatQuestion}>
      <div className={s.chatQuestion__text}>{text}</div>

      <Avatar size="small" image={avatar} alt={""} />
    </div>
  )
}

const ChatResponse = ({
  text,
  isLoading = false,
  avatar,
  isError,
}: {
  text: string
  isLoading?: boolean
  avatar: string
  isError: boolean
}) => {
  return (
    <div className={s.chatResponse} aria-busy={isLoading}>
      <Avatar size="small" image={avatar} alt="" />

      <div className={modifiers(s, "chatResponse__text", { isError })}>
        {isError && <AlertIcon className="chatResponse__alert-icon" />}
        <span aria-live="polite">{text}</span>
        {/* TODO Improve loading animation. Just here to indicate loading for now */}
        {isLoading && <div className={s.loader} />}
      </div>
    </div>
  )
}

const CloseButton = ({ onClose }: { onClose: () => void }) => (
  <button className={s.closeButton} onClick={onClose} aria-label="close">
    <img className={s.closeButton__icon} src={closeIcon} alt="" />
  </button>
)
