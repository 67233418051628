import {
  useQuery,
  type QueryFunction,
  type QueryKey,
  type UseQueryResult,
} from "@tanstack/react-query"
import { print } from "graphql"
import { useContext } from "react"
import { never } from "utils"
import { type TypedDocumentNode } from "."
import { EnvironmentContext } from "./EnvironmentContext"
import { ForceStateContext, MockInputMapContext, mockMap } from "./context"
import { graphqlRequest } from "./graphqlRequest"

type Options<TData> = {
  initialData?: TData
  enabled?: boolean
  gcTime?: number
}

export const useGraphqlQuery = <TData, TVariables extends Record<string, any>>(
  query: TypedDocumentNode<TData, TVariables>,
  variables: TVariables,
  options?: Options<TData>
): UseQueryResult<TData> => {
  const forceState = useContext(ForceStateContext)
  const mockInputMap = useContext(MockInputMapContext)
  const environment = useContext(EnvironmentContext)
  if (!environment) throw new Error("Missing environment context")

  const enabled = options?.enabled === false ? false : forceState === "live"

  const queryKey = [print(query), variables]

  const queryFn: QueryFunction<TData, QueryKey> = ({ signal }) => {
    return graphqlRequest({ query, variables, environment, signal })
  }

  const queryResult = useQuery<TData>({
    enabled,
    queryKey,
    queryFn,
    gcTime: options?.gcTime,
    initialData: options?.initialData,
  })

  if (forceState === "live") {
    return queryResult
  }

  if (forceState === "loading") {
    return {
      ...queryResult,
      data: undefined,
      error: null,
      isPending: true,
      isError: false,
      isLoading: true,
      isLoadingError: false,
      isRefetchError: false,
      isSuccess: false,
      status: "pending",
      refetch: never,
    }
  }

  if (forceState === "mock") {
    const mockInputData = mockInputMap?.get(query)
    const mockData = mockMap.get(query)
    const data = mockInputData ?? mockData

    return {
      ...queryResult,
      data,
      error: null,
      isPending: false,
      isError: false,
      isLoading: false,
      isLoadingError: false,
      isRefetchError: false,
      isSuccess: true,
      status: "success",
      refetch: never,
    }
  }

  if (forceState === "error") {
    return {
      ...queryResult,
      data: {} as TData,
      error: new Error("This is a forced error. This is only a test."),
      isPending: false,
      isError: true,
      isLoading: false,
      isLoadingError: false,
      isRefetchError: true,
      isSuccess: false,
      status: "error",
      refetch: never,
    }
  }

  return queryResult
}
