import { type QueryOptions } from "@tanstack/react-query"
import { QueryClient } from "bonzai"
import { isFetchError } from "fetcher"

const onRetry: QueryOptions["retry"] = (failureCount, error) => {
  const possibleNetworkError = isFetchError(error) && error.didRequestFail
  if (possibleNetworkError) return failureCount < 3

  return false
}

export const createQueryClient = () => {
  return new QueryClient({
    defaultOptions: {
      queries: {
        retry: onRetry,
      },
    },
  })
}
