import activityLeaderboardIcon from "assets/icons/activityLeaderboardIcon.svg"
import completedIcon from "assets/icons/completedIcon.svg"
import recentActivityIcon from "assets/icons/recentActivityIcon.svg"
import { useGraphqlQuery } from "graphql-mock"
import type { TFunction } from "i18next"
import { Card } from "materia"
import { useTranslation } from "react-i18next"
import { modifiers } from "ui-tools"
import { exists } from "utils"
import { useGetOrdinal } from "../../../dataUtilities/getOrdinal"
import { Avatar } from "../../Avatar"
import { Stars } from "../../Stars"
import { RecentActivityWidgetError } from "./error"
import { query, type RecentActivityEvent } from "./gql"
import s from "./styles.module.scss"

export const RecentActivityWidget = () => {
  const { t } = useTranslation()

  const { data, error, isPending } = useGraphqlQuery(query, {})

  if (isPending) return <RecentActivityWidgetLoading />
  if (error) return <RecentActivityWidgetError error={error} />

  const { currentCompany, completed, leaderboard, stars } = data
  const { portalFeatureFlags } = currentCompany

  if (!portalFeatureFlags.includes("enable_activity_feed")) return

  const events = [completed, leaderboard, stars]
    .map((data) => data.nodes[0])
    .filter(exists)

  if (events.length === 0) return

  return (
    <Card borderRadius="24">
      <div className={s.widget}>
        <div className={s.widget__top}>
          <img className={s.widget__icon} src={recentActivityIcon} alt="" />
          <h2 className={s.widget__title}>{t("activityFeed.activity")}</h2>
        </div>
        <ul className={s.widget__entries}>
          {events.map((item, index) => (
            <Entry key={index} event={item} />
          ))}
        </ul>
      </div>
    </Card>
  )
}

const Entry = ({ event }: { event: RecentActivityEvent }) => {
  const { t } = useTranslation()
  const getOrdinal = useGetOrdinal()

  const { user, product, createdAt, eventType, rank, stars } = event

  const getSummaryText = () => {
    if (eventType === "stars" && stars)
      return t("activityFeed.stars", { count: stars })
    if (eventType === "leaderboard" && rank) return getOrdinal(rank)
    return t("activityFeed.completed")
  }

  const entryClass = modifiers(s, "entry", {
    orange: eventType === "leaderboard",
    green: eventType === "completed",
  })

  const getIcon = () => {
    if (eventType === "completed") return completedIcon
    if (eventType === "leaderboard") return activityLeaderboardIcon
  }

  return (
    <li className={entryClass}>
      <span className={s.entry__avatarWrapper}>
        <Avatar alt={user.avatarTitle} image={user.avatar} size="medium" />
      </span>

      <div className={s.entry__name}>{user.displayName}</div>
      <div className={s.entry__time}>{displayTimestamp(createdAt, t)}</div>
      <div className={s.entry__summary}>
        {eventType === "stars" ? (
          <Stars count={1} max={1} size="extraSmall" />
        ) : (
          <img className={s.entry__icon} src={getIcon()} alt="" />
        )}
        <div className={s.entry__summaryText}>{getSummaryText()}</div>
        <div className={s.entry__product}>{product.title}</div>
      </div>
    </li>
  )
}

export const RecentActivityWidgetLoading = () => {
  return (
    <Card borderRadius="24">
      <div className={s.skeleton}>
        <div className={s.skeleton__top}>
          <div className={s.skeleton__icon} />
          <div className={s.skeleton__title} />
        </div>
        <ul className={s.skeleton__entries}>
          <SkeletonEntry />
          <SkeletonEntry />
          <SkeletonEntry />
        </ul>
      </div>
    </Card>
  )
}

const SkeletonEntry = () => (
  <li className={s.skeletonEntry}>
    <div className={s.skeletonEntry__avatar} />
    <div className={s.skeletonEntry__title} />
    <div className={s.skeletonEntry__summary} />
  </li>
)

const displayTimestamp = (timestamp: string, t: TFunction) => {
  const time = new Date(timestamp)
  const now = new Date()
  const difference = now.getTime() - time.getTime()

  const ONE_HOUR = 1000 * 60 * 60
  const ONE_DAY = ONE_HOUR * 24
  const THREE_DAYS = ONE_DAY * 3

  const hours = Math.floor(difference / ONE_HOUR)
  const days = Math.floor(difference / ONE_DAY)

  if (difference < ONE_HOUR) return t("date.JUST_NOW")
  if (difference < ONE_DAY) return hours + t("date.HOUR_SHORT")
  if (difference < THREE_DAYS) return days + t("date.DAY_SHORT")
  return t("date.A_WHILE_AGO")
}
