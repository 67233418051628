import { useQueryParam } from "hooks"
import { useEffect, useState } from "react"
import { useDownloadHTMLAsPDF } from "../../tools/useDownloadHTMLAsPDF"

export const useDownloadDiploma = (
  productId: string,
  isDiplomaFetched: boolean
) => {
  // "autodownload" query param indicates that we want to download
  // the diploma PDF automatically once we mount the component.
  // This allows us to fix issue with SKILLS where we cannot open
  // a data URI, so instead we redirect to external browser with
  // "autodownload" param set to "true" and then download the file.
  const [autoDownload, setAutoDownload] = useQueryParam("autodownload")
  // A flag to make sure that autodownload is only initialized once
  const [isAutoDownloadInitialized, setIsAutoDownloadInitialized] =
    useState(false)

  const skillsDownloadLink = `${window.location.href}${
    window.location.search ? "&" : "?"
  }autodownload=true`

  const optionsAttensi = {
    eventTrackKey: "Diploma download",
    filename: `diploma-${productId}`,
    skillsDownloadLink,
  }

  const optionsCanvas = {
    onclone: (document: Document) => {
      // Workaround for a html2canvas bug - text that is supposed to be
      // at the top of the ribbon is shifted down by 16px
      const ribbonText = document.getElementById("ribbon__text")
      if (ribbonText) ribbonText.style.marginTop = "-16px"
    },
  }

  const { isDownloadInProgress, printRef, onDownload } = useDownloadHTMLAsPDF(
    optionsAttensi,
    optionsCanvas
  )

  const isAutoDownload = autoDownload === "true"
  const isAutoDownloadFinished =
    isAutoDownload && !isDownloadInProgress && isAutoDownloadInitialized

  const onAutoDownloadClose = () => {
    // Autodownload is intended to use only in SKILLS app,
    // so after it's done, redirect back to app
    window.location.href = "attensi-skillspicker://"
    window.close()
  }

  useEffect(() => {
    const shouldStartAutoDownload =
      isAutoDownload && !isAutoDownloadInitialized && isDiplomaFetched
    if (shouldStartAutoDownload) {
      setIsAutoDownloadInitialized(true)
      onDownload()
    }
  }, [
    isAutoDownload,
    isAutoDownloadInitialized,
    isDiplomaFetched,
    onDownload,
    setAutoDownload,
  ])

  return {
    isAutoDownload,
    isAutoDownloadFinished,
    isDownloadInProgress,
    printRef,
    onDownload,
    onAutoDownloadClose,
  }
}
