import { type ResultOf } from "graphql-mock"
import { graphql } from "../../../graphql"

export type Data = ResultOf<typeof query>

export const query = graphql(`
  query CurrentCompanyProvider($locale: String!) {
    currentCompany {
      backgroundUrl
      companyFeatureFlags
      companyButtonColor
      companyStarColor
      defaultLocale
      enabledApps
      id
      logoUrl
      name
      nameKey
      portalBackgroundUrl
      portalFeatureFlags
      portalLandingPage
      portalSupportedLocales
      privacyPolicy(locale: $locale)
      selfRegistration
      userDisplayNameFormat
    }
  }
`)
