import bowser from "bowser"
import type { Platform } from "../graphql"

// This can be a little confusing.
// It's specifically referring to the "builds" we support on the back-end (and link to), and not the actual OS or platform of the device itself.
// We're trying to convey to the back-end, that "this is the platform that we wish to receive links for"
// Fx. if we request play links for a product as a "Windows" platform, we might get a launcher and webgl link,
// but if we request play links for the same product, as "MacOS", we might only get a webgl link. It will depend on what the product supports.

// This is back-end logic on the front-end as we determine what kind of product links we should ask for.
// This should probably be done on the back-end in the future, by detecting the user agent, or by passing the actual OS of the device,
// and letting the back-end be in full control of what to deliver based on the device platform.
export const getAttensiProductPlatformForOS = (): Platform | undefined => {
  const parser = bowser.getParser(window.navigator.userAgent)
  return getAttensiProductPlatform(parser.getOS().name)
}

const getAttensiProductPlatform = (
  operatingSystem: string | undefined
): Platform | undefined => {
  // List of possible OS
  // https://github.com/lancedikson/bowser/blob/master/src/constants.js#L93

  switch (operatingSystem) {
    case "Windows":
    case "iOS":
    case "Android":
      return operatingSystem
    case "macOS":
      return "MacOS"
    default:
      return "WebGL"
  }
}
