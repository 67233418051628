import { type ReactNode } from "react"
import { modifiers } from "ui-tools"
import { Dialog } from "../Dialog"
import s from "./Modal.module.scss"

type ModalProps = {
  children: ReactNode
  ariaLabel: string
  onClose: () => void
  overflowAuto?: boolean
  isFullScreenMobile?: boolean
}
export const Modal = ({
  onClose,
  overflowAuto = true,
  children,
  ariaLabel,
  isFullScreenMobile = false,
}: ModalProps) => {
  return (
    <Dialog className={s.modal} ariaLabel={ariaLabel} onClose={onClose}>
      <div
        className={modifiers(s, "modal__content", {
          overflowAuto,
          isFullScreenMobile,
        })}
      >
        {children}
      </div>
    </Dialog>
  )
}
