import checkmarkCircleGreen from "assets/icons/checkmarkCircleGreen.svg"
import { ProgressBar, useErrorSentryLog } from "materia"
import { useTranslation } from "react-i18next"
import { modifiers } from "ui-tools"
import { FiestaLink } from "utility-components"
import { Stars } from "../../Stars"
import s from "./styles.module.scss"

export const UserInfoHeaderError = ({
  isMandatory,
  isIncomplete,
  error,
}: {
  isMandatory: boolean
  isIncomplete: boolean
  error: unknown | unknown[]
}) => {
  // TODO [sentry-logging] https://jira.attensi.com/browse/WEB-18745
  // Find a better place to send Sentry logs from
  useErrorSentryLog(error)
  const { t } = useTranslation()

  return (
    <div className={s.error}>
      <div className={s.row}>
        <div className={s.stars}>
          <Stars count={1} max={1} size="extraSmall" />
          <div className={s.stars__value}>?/?</div>
        </div>
        <div className={modifiers(s, "completed", { isActive: false })}>
          <img
            className={s.completed__icon}
            src={checkmarkCircleGreen}
            alt=""
          />
          <div className={s.completed__value}>?</div>
          <div className={s.completed__label}>{t("user.COMPLETED")}</div>
        </div>

        <FiestaLink
          className={modifiers(s, "filter", "mandatory")}
          to="/for-you"
          search={{ mandatory: !isMandatory }}
        >
          <div className={s.filter__value}>?</div>
          <div className={s.filter__label}>{t("user.MANDATORY")}</div>
        </FiestaLink>
        <FiestaLink
          className={modifiers(s, "filter", "incomplete")}
          to="/for-you"
          search={{ incomplete: !isIncomplete }}
        >
          <div className={s.filter__value}>?</div>
          <div className={s.filter__label}>{t("user.INCOMPLETE")}</div>
        </FiestaLink>
      </div>
      <div className={s.progress}>
        <ProgressBar value={0} maxValue={1} />
      </div>
    </div>
  )
}
