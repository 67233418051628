import { ErrorFallback } from "materia"
import { useTranslation } from "react-i18next"
import { UserProfileSection } from "../UserProfileSection"

export const UserProfilesCertificateError = ({ error }: { error: unknown }) => {
  const { t } = useTranslation()
  return (
    <UserProfileSection title={t("userProfile.SECTION_CERTIFICATES")}>
      <ErrorFallback
        error={error}
        title={t("error.GENERIC")}
        message={t("error.COULD_NOT_LOAD")}
      />
    </UserProfileSection>
  )
}
