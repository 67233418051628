import closeIconWhite from "assets/icons/closeIconWhite.svg"
import { useAccessParams } from "auth"
import { useGraphqlQuery } from "graphql-mock"
import { useAsyncData, useModal, useQueryParam } from "hooks"
import { Dialog, Loader } from "materia"
import { useEffect, useState, type ReactNode } from "react"
import { useTranslation } from "react-i18next"
import { modifiers } from "ui-tools"
import { withQueryParams } from "utils"
import { ATTENSI_PRODUCT_PLATFORM_FOR_OS } from "../../constants"
import { getVideoLink } from "../../dataUtilities/getVideoLink"
import {
  getPreferredContentLink,
  getPreferredUpload,
} from "../../dataUtilities/productDataUtilities"
import { createPlaythrough } from "../../graphql/createPlaythrough"
import { addPlayLinkParams } from "../../tools/addPlayLinkParams"
import { useIframeMessages } from "../../tools/useIframeMessages"
import { usePageTitle } from "../../tools/usePageTitle"
import { sendVuplexMessage } from "../../vuplex/sendVuplexMessage"
import { useBlockConfettiBanner } from "../ConfettiBanner/useBlockConfettiBanner"
import {
  FramedViewerError,
  FullScreenViewerError,
  ViewerErrorDialog,
} from "./error"
import { query, type Data } from "./gql"
import s from "./styles.module.scss"
import { VideoPlayer } from "./VideoPlayer"

export const ViewerModal = ({
  closeRoute,
  productId,
}: {
  closeRoute: string
  productId: string
}) => {
  useBlockConfettiBanner()

  const { onClose } = useModal(closeRoute)

  const [locale] = useQueryParam("content_locale")

  const { data, error, isPending } = useGraphqlQuery(query, {
    productId,
    platform: ATTENSI_PRODUCT_PLATFORM_FOR_OS,
  })

  usePageTitle(data?.product.title)

  if (error) return <ViewerErrorDialog error={error} onClose={onClose} />

  return (
    <Dialog ariaLabel="Product content" onClose={onClose}>
      <div className={s.content}>
        {isPending ? (
          <Loader />
        ) : (
          <Switcher data={data} onClose={onClose} locale={locale} />
        )}
      </div>
    </Dialog>
  )
}

const Switcher = ({
  data,
  onClose,
  locale,
}: {
  data: Data
  onClose: () => void
  locale: string | undefined
}) => {
  const { productType } = data.product

  switch (productType) {
    case "skill":
    case "behavior":
    case "operations":
    case "process":
    case "workflow":
    case "act":
      return <WebGL data={data} onClose={onClose} />
    case "pdf":
      return <Pdf data={data} onClose={onClose} locale={locale} />
    case "video":
      return <Video data={data} onClose={onClose} locale={locale} />
    case "xapi":
      return <Xapi data={data} onClose={onClose} />
    case "fast":
    case "quiz_race":
    case "assessment":
    case "process_on_web":
      return <WebGames data={data} onClose={onClose} />
  }

  throw new Error("Cannot view this product type")
}

const Pdf = ({
  data,
  onClose,
  locale,
}: {
  data: Data
  onClose: () => void
  locale: string | undefined
}) => {
  const { productUploads, title, contentLink } = data.product

  const src = getPreferredUpload({ productUploads, locale, contentLink })

  return (
    <FullScreenViewer headerText={title} onClose={onClose}>
      <Iframe src={src} />
    </FullScreenViewer>
  )
}

const Video = ({
  data,
  onClose,
  locale,
}: {
  data: Data
  onClose: () => void
  locale: string | undefined
}) => {
  const { product, currentCompany } = data
  const { localeContentLinks, title, contentLink } = data.product

  const [shouldCreatePlaythrough, setShouldCreatePlaythrough] = useState(false)

  const isSkipForwardDisabled =
    currentCompany.companyFeatureFlags.includes("enable_no_skip_videos") &&
    product.noSkip &&
    !product.progress.certified

  const link = getPreferredContentLink({
    localeContentLinks,
    locale,
    contentLink,
  })

  const videoLink = getVideoLink(link)

  const createVideoPlaythrough = () => {
    if (shouldCreatePlaythrough || !isSkipForwardDisabled) {
      createPlaythrough(product.id)
    }
  }

  return (
    <FullScreenViewer
      headerText={title}
      onClose={() => {
        createVideoPlaythrough()
        onClose()
      }}
    >
      <VideoPlayer
        src={videoLink}
        isSkipForwardDisabled={isSkipForwardDisabled}
        onPlaythroughComplete={() => setShouldCreatePlaythrough(true)}
      />
    </FullScreenViewer>
  )
}

const WebGames = ({ data, onClose }: { data: Data; onClose: () => void }) => {
  const { i18n } = useTranslation()

  const { contentLink, title } = data.product

  useIframeMessages({
    targetUrl: contentLink,
    onMessage: ({ type }) => {
      if (type === "EXIT_MODAL") onClose()
    },
  })

  const url = withQueryParams(contentLink, { locale: i18n.language })
  const { link, isPending, error } = useAccessParams(url)

  if (isPending) return <Loader />
  if (error) return <FullScreenViewerError error={error} onClose={onClose} />

  return (
    <FullScreenViewer headerText={title} onClose={onClose}>
      <Iframe src={link} />
    </FullScreenViewer>
  )
}

const Xapi = ({ data, onClose }: { data: Data; onClose: () => void }) => {
  useAutoRotation()

  const { contentLink, title } = data.product
  const { link, isPending, error } = useAccessParams(contentLink)

  if (isPending) return <Loader />
  if (error) return <FullScreenViewerError error={error} onClose={onClose} />

  return (
    <FullScreenViewer headerText={title} onClose={onClose}>
      <Iframe src={link} />
    </FullScreenViewer>
  )
}

const WebGL = ({ data, onClose }: { data: Data; onClose: () => void }) => {
  const { product } = data
  const { data: src, isPending, error } = useWebglLink(data)

  const orientation = product.productType === "skill" ? "portrait" : "landscape"
  if (isPending) return <Loader />
  if (error)
    return (
      <FramedViewerError
        error={error}
        onClose={onClose}
        orientation={orientation}
      />
    )

  if (src === undefined) throw new Error(`No webGLLink for product`)

  return <FramedViewer src={src} orientation={orientation} onClose={onClose} />
}

const FramedViewer = ({
  src,
  orientation,
  onClose,
}: {
  src: string
  orientation: "portrait" | "landscape"
  onClose: () => void
}) => {
  return (
    <div className={modifiers(s, "webgl", orientation)}>
      <div className={s.webgl__closeButton}>
        <CloseButton onClick={onClose} />
      </div>
      <div className={s.webgl__content}>
        <Iframe src={src} />
      </div>
    </div>
  )
}

const FullScreenViewer = ({
  headerText,
  onClose,
  children,
}: {
  headerText: string | null
  onClose: () => void
  children: ReactNode
}) => {
  return (
    <div className={s.fullScreen}>
      <div className={s.fullScreen__header}>
        <div>{headerText}</div>
        <CloseButton onClick={onClose} />
      </div>
      <div className={s.fullScreen__content}>{children}</div>
    </div>
  )
}

export const CloseButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <button className={s.closeButton} onClick={onClick} aria-label="Close">
      <img className={s.closeButton__icon} src={closeIconWhite} alt="" />
    </button>
  )
}

const Iframe = ({ src }: { src: string }) => {
  return (
    <iframe
      className={s.iframe}
      src={src}
      title="Attensi product viewer"
      allow="autoplay; encrypted-media"
      allowFullScreen
    />
  )
}

const useAutoRotation = () => {
  useEffect(() => {
    sendVuplexMessage({
      type: "SET_SCREEN_ORIENTATION",
      payload: { screenOrientation: "AUTO" },
    })

    return () => {
      sendVuplexMessage({
        type: "SET_SCREEN_ORIENTATION",
        payload: { screenOrientation: "PORTRAIT" },
      })
    }
  }, [])
}

const useWebglLink = ({ product }: Data) => {
  return useAsyncData(
    () =>
      addPlayLinkParams({
        link: product.playLinks.webglLink,
        useTransferToken: product.useTransferToken,
      }),
    [product.id]
  )
}
