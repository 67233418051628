import { JSONParser } from "@streamparser/json"
import { auth } from "auth"
import { useState } from "react"

// Link to documentation: https://www.npmjs.com/package/@streamparser/json#user-content-stream-parsing-a-fetch-request-returning-a-json-array
// We use this specifically for parsing a streamed array of JSON objects coming in from the /api/Flowise/query end-point
const jsonparser = new JSONParser({
  stringBufferSize: undefined,
  paths: ["$.*"],
  separator: "",
})

type FlowiseResponseFragment = {
  fragmentType: "Start" | "Token" | "End" | "Noop" | "Response"
  text: string
}

// This hook is intended to be used for asking questions to our own AI API
// The answer will be returned as a Stream of an array containing JSON objects
// The JSON objects represent FlowiseResponseFragments, that each try to describe their meaning in the context of an ongoing stream.
// The API is actively worked on at this moment, and we lack fx. a clear agreement on if fx. the back-end should wrap and generalize errors for the clients,
// or if we are to handle it on our own.
// Link to Swagger documentation for AI-API: https://ai-api.attensi.com/swagger/index.html
export const usePostFlowiseQuestionStream = (flowId: string) => {
  const [isLoading, setIsLoading] = useState(false)

  const askQuestion = async (
    question: string,
    onChunk: (chunk: FlowiseResponseFragment) => void,
    onError: (errorMessage: string) => void
  ) => {
    // This function is called by jsonparser.write(), and is the handler for the JSON stream parser
    jsonparser.onValue = ({ value }) => {
      const flowiseResponseFragment = value as FlowiseResponseFragment

      // We want to ignore these "meta" fragments. At least for the moment.
      if (
        flowiseResponseFragment.fragmentType === "End" ||
        flowiseResponseFragment.fragmentType === "Response" ||
        flowiseResponseFragment.fragmentType === "Noop"
      ) {
        return
      }

      onChunk(flowiseResponseFragment)
    }

    try {
      setIsLoading(true)
      // Need to fetch on our own for now, as i haven't found a Fiesta utility that supports streams
      const { accessToken } = await auth.getActiveSession()
      const request = getFlowiseRequest(accessToken, question, flowId)
      const response = await fetch(request)
      if (!response.body) return
      const reader = response.body.getReader()
      // Infinite loop
      for (;;) {
        const { done, value } = await reader.read()
        if (done) break

        // // Will tokenize, parse and call onValue(), with the streamed chunk
        jsonparser.write(value)
      }
    } catch (error) {
      console.log("Error on POST /api/Flowise/query: ", error)
      onError("Something went wrong. Please try again") // TODO: figure out a proper way to handle errors between Flowise and us
    } finally {
      setIsLoading(false)
    }
  }

  return { askQuestion, isLoading }
}

const getFlowiseRequest = (
  accessToken: string,
  question: string,
  flowId: string
) => {
  return new Request("https://ai-api.attensi.com/api/Flowise/query", {
    method: "POST",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      context: {
        productId: 0,
        scenarioToken: "string",
        clientName: "string",
        clientVersion: "string",
      },
      question: question,
      flow: flowId,
      chatId: "string",
      stream: true,
      instance: "Production",
      indexSize: "Small",
    }),
  })
}
