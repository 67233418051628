import activityLeaderboardIcon from "assets/icons/activityLeaderboardIcon.svg"
import closeIcon from "assets/icons/closeIcon.svg"
import completedIcon from "assets/icons/completedIcon.svg"
import recentActivityIcon from "assets/icons/recentActivityIcon.svg"
import starFilled from "assets/icons/starFilled.svg"
import { useGraphqlQuery } from "graphql-mock"
import { useModal } from "hooks"
import type { TFunction } from "i18next"
import { Modal } from "materia"
import { useTranslation } from "react-i18next"
import { modifiers } from "ui-tools"
import { exists } from "utils"
import { Avatar } from "../../../components/Avatar"
import { Stars } from "../../../components/Stars"
import { useGetOrdinal } from "../../../dataUtilities/getOrdinal"
import { usePageTitle } from "../../../tools/usePageTitle"
import { RecentActivityWidgetModalError } from "./error"
import { query, type Data, type RecentActivityEvent } from "./gql"
import s from "./styles.module.scss"

export const RecentActivityWidgetModal = ({
  closeRoute,
}: {
  closeRoute: string
}) => {
  const { t } = useTranslation()
  usePageTitle(t("pageTitle.RECENT_ACTIVITY"))

  const { onClose } = useModal(closeRoute)

  const { data, error, isPending, isSuccess } = useGraphqlQuery(query, {})

  return (
    <Modal onClose={onClose} ariaLabel="Recent activity">
      {isPending && <RecentActivityWidgetModalLoading />}
      {error && <RecentActivityWidgetModalError error={error} />}
      {isSuccess && <Content data={data} onClose={onClose} />}
    </Modal>
  )
}

const Content = ({ data, onClose }: { data: Data; onClose: () => void }) => {
  const { t } = useTranslation()

  const { completed, leaderboard, stars } = data

  const events = [completed, leaderboard, stars]
    .map((data) => data.nodes[0])
    .filter(exists)

  return (
    <div className={s.card}>
      <div className={s.card__top}>
        <div className={s.card__topText}>
          <img className={s.card__icon} src={recentActivityIcon} alt="" />
          <h2 className={s.card__title}>{t("activityFeed.activity")}</h2>
        </div>
        <button className={s.closeButton} onClick={onClose} aria-label="Close">
          <img className={s.closeButton__icon} src={closeIcon} alt="" />
        </button>
      </div>
      <ul className={s.card__entries}>
        {events.map((item, index) => (
          <Entry key={index} event={item} />
        ))}
      </ul>
    </div>
  )
}

const Entry = ({ event }: { event: RecentActivityEvent }) => {
  const { t } = useTranslation()
  const getOrdinal = useGetOrdinal()

  const { user, product, createdAt, eventType, rank, stars } = event

  const entryClass = modifiers(s, "entry", {
    orange: eventType === "leaderboard",
    green: eventType === "completed",
  })

  const getSummaryText = () => {
    if (eventType === "stars" && stars)
      return t("activityFeed.stars", { count: stars })
    if (eventType === "leaderboard" && rank) return getOrdinal(rank)
    return t("activityFeed.completed")
  }

  const getIcon = () => {
    if (eventType === "completed") return completedIcon
    if (eventType === "leaderboard") return activityLeaderboardIcon
    if (eventType === "stars") return starFilled
  }

  return (
    <li className={entryClass}>
      <Avatar alt={user.avatarTitle} image={user.avatar} size="medium" />

      <div className={s.entry__insightWrapper}>
        <div className={s.entry__topRow}>
          <span className={s.entry__name}>{user.displayName}</span>
          <span className={s.entry__time}>
            {displayTimestamp(createdAt, t)}
          </span>
        </div>

        <div className={s.entry__summary}>
          {eventType === "stars" ? (
            <Stars count={1} max={1} size="extraSmall" />
          ) : (
            <img className={s.entry__icon} src={getIcon()} alt="" />
          )}
          <span className={s.entry__summaryText}>{getSummaryText()}</span>
          <span className={s.entry__product}>{product.title}</span>
        </div>
      </div>
    </li>
  )
}

const RecentActivityWidgetModalLoading = () => {
  return (
    <div className={s.skeleton}>
      <div className={s.skeleton__top}>
        <div className={s.skeleton__icon} />
        <div className={s.skeleton__title} />
      </div>
      <ul className={s.skeleton__entries}>
        <SkeletonEntry />
        <SkeletonEntry />
        <SkeletonEntry />
      </ul>
    </div>
  )
}

const SkeletonEntry = () => (
  <li className={s.skeletonEntry}>
    <div className={s.skeletonEntry__avatar} />
    <div className={s.skeletonEntry__title} />
    <div className={s.skeletonEntry__summary} />
  </li>
)

const displayTimestamp = (timestamp: string, t: TFunction) => {
  const time = new Date(timestamp)
  const now = new Date()
  const difference = now.getTime() - time.getTime()

  const ONE_HOUR = 1000 * 60 * 60
  const ONE_DAY = ONE_HOUR * 24
  const THREE_DAYS = ONE_DAY * 3

  const hours = Math.floor(difference / ONE_HOUR)
  const days = Math.floor(difference / ONE_DAY)

  if (difference < ONE_HOUR) return t("date.JUST_NOW")
  if (difference < ONE_DAY) return hours + t("date.HOUR_SHORT")
  if (difference < THREE_DAYS) return days + t("date.DAY_SHORT")
  return t("date.A_WHILE_AGO")
}
