import { useGraphqlQuery } from "graphql-mock"
import { useWindowWidth } from "materia"
import type { ReactNode } from "react"
import { useEffect, useRef, useState } from "react"
import { backgroundImageStyle, modifiers } from "ui-tools"
import { ErrorPopup, QueryBoundary } from "utility-components"
import { ProductMiniModal } from "../ProductMiniModal"
import { query, type Data } from "./gql"
import { Header } from "./Header"
import { NavigationBar } from "./Navigation"
import { NoPortalAccess } from "./NoPortalAccess"
import s from "./styles.module.scss"

export const AppShell = ({ children }: { children: ReactNode }) => {
  const { data, error, isPending } = useGraphqlQuery(query, {})

  if (error) return <ErrorPopup error={error} />

  const flags = data?.currentCompany.portalFeatureFlags

  const backgroundImage = flags?.includes("enable_portal_background")
    ? data?.currentCompany.portalBackgroundUrl
    : undefined

  const hasAccess = isPending
    ? true
    : data.currentCompany.enabledApps.includes("portal")

  return (
    <div className={s.app} style={backgroundImageStyle(backgroundImage)}>
      <Header />
      <main id="main" className={s.main}>
        <QueryBoundary>
          {hasAccess ? children : <NoPortalAccess />}
        </QueryBoundary>
      </main>
      <QueryBoundary>
        <BottomBar data={data} />
      </QueryBoundary>
    </div>
  )
}

const BottomBar = ({ data }: { data: Data | undefined }) => {
  const isHidden = useIsBottomBarHidden()

  return (
    <div className={modifiers(s, "bottomBar", { isHidden })}>
      <ProductMiniModal />
      <BottomNavigation>
        {data && <NavigationBar data={data.navigationCompany} />}
      </BottomNavigation>
    </div>
  )
}

const BottomNavigation = ({ children }: { children: ReactNode }) => {
  const { isBigWindow } = useWindowWidth()
  if (isBigWindow) return null

  return <div className={s.bottomNavigationBar}>{children}</div>
}

/**
 * According to a11y guidelines content needs to be accessible in
 * two dimensions as small as 320x256 pixels. Bottom navigation bar
 * takes up a lot of space on such a small screen. To solve, we can
 * show it only when user scrolls in one direction and hide when
 * they scroll in the other.
 * https://www.w3.org/WAI/WCAG22/Techniques/css/C34.html
 */
const useIsBottomBarHidden = () => {
  const [isBottomBarHidden, setIsBottomBarHidden] = useState(false)
  const prevScrollY = useRef(window.scrollY)

  useEffect(() => {
    const onScroll = () => {
      const currentScrollY = window.scrollY
      const isScrollingDown = prevScrollY.current < currentScrollY
      setIsBottomBarHidden(isScrollingDown)
      prevScrollY.current = currentScrollY
    }

    window.addEventListener("scroll", onScroll)

    return () => {
      window.removeEventListener("scroll", onScroll)
    }
  }, [])

  return isBottomBarHidden
}
